import { QueryClient } from '@tanstack/react-query';

export const ROUTES = {
  DETAILS: '/apps',
  FEATURED: '/',
  BROWSE: '/apps/browse',
  MANAGE: '/manage',
  PUBLISHER: '/publisher',
  ABOUT: '/about',
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 1000,
      retry: 1,
    },
  },
});

/**
 * @typedef {Object} FeatureFlag
 * @property {string} key
 * @property {string} client
 */

/** @type {Record<'SP_ISV', FeatureFlag>} */
export const FEATURED_FLAGS = Object.freeze({
  SP_ISV: {
    key: 'SP_ISV',
    client: 'IO_Global_Features',
  },
});

export const LINKS = {
  SP: { VIEW_DOCS: 'https://adobe.com/go/sp_docs' },
};
